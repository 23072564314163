import React from 'react';

function getCost(plan,cost_id) {
    var costElement = document.getElementById(cost_id);
    var planChoosen = document.getElementById(plan).value;
    if (planChoosen !== '') {
        var costInDollar = parseInt(planChoosen) * 30;
        var costInNaira = costInDollar * 420;
        costElement.value = '$'+costInDollar +' '+ '= ' +"N"+costInNaira;
        costElement.readOnly = true;
    }else{
        costElement.value = '';
    }

}

export default function Pricing() {
    return(
        <section className="bg-light o-hidden" id="pricing">
            <div className="container">
                <div className="row section-title justify-content-center text-center">
                <div className="col-md-9 col-lg-8 col-xl-7">
                    <h3 className="display-4">Our Pricing</h3>
                    <div className="lead">We Use Technologies such as HTML, CSS, Bootsrap, Javascript, React, Redux, React Native Jquery, PHP, Node Js, Java, C# and more...</div>
                    <div className="lead">We use the best combinations of these Technologies in the development/building of Digital Products</div>
                </div>
                </div>
                <div className="container">
                <div className="row">
                    <div className="col">
                    <ul className="nav nav-pills lead mb-4 mb-md-5 justify-content-center" id="myTab" role="tablist">
                        <li className="nav-item">
                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#Ecommerce" role="tab" aria-controls="home" aria-selected="true">E-commerce</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#Personal" role="tab" aria-controls="profile" aria-selected="false">Personal</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" id="contact-tab" data-toggle="tab" href="#Brand" role="tab" aria-controls="contact" aria-selected="false">Brand</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" id="contact-tab" data-toggle="tab" href="#anderoid_app" role="tab" aria-controls="contact" aria-selected="false">Android App</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" id="contact-tab" data-toggle="tab" href="#pwa" role="tab" aria-controls="contact" aria-selected="false">PWA</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" id="contact-tab" data-toggle="tab" href="#advertisement" role="tab" aria-controls="contact" aria-selected="false">Advertisement</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" id="contact-tab" data-toggle="tab" href="#entertainment" role="tab" aria-controls="contact" aria-selected="false">Entertainment</a>
                        </li>
                    </ul>
                    {/* <h3 className="display-5 text-center">Pricing for building: </h3> */}
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="Ecommerce" role="tabpanel" aria-labelledby="home-tab">
                        <div className="row align-items-center justify-content-between flex-lg-row-reverse">
                        <div className="col-lg-6 col-xl-5 text-center text-lg-left mb-4 mb-md-5 mb-lg-0">
                            <div className="pl-lg-4 pr-xl-5" data-aos="fade-right">
                                <h3 className="h3">An Ecommerce website</h3>
                                <p className="desc">
                                Ned ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
                                </p>
                                <div className="" data-aos="fade-left" data-aos-delay="250">
                                    <div className="card card-body shadow-lg">
                                        <form data-form-email noValidate >
                                        <div className="form-group">
                                            <label htmlFor="project1">Select category</label>
                                            <div className="position-relative">
                                            <select id="project1" onChange={() => getCost('project1','cost1')} className="custom-select" >
                                                <option value="" >Select a plan</option>
                                                <option value="15">Basic (3wks - 5 days per wk) : 15 days</option>
                                                <option value="20">Premium (4wks - 5 days per wk) : 20 days</option>
                                                <option value="25">Pro (4wks - 5 days per wk) : 25 days</option>
                                            </select>
                                            <img src="assets/img/icons/interface/icon-caret-down.svg" alt="Arrow Down" className="icon icon-sm"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="costPerHr">Cost per hour</label>
                                            <input id="costPerHr" name="" type="text" disabled className="form-control" placeholder="" value="$5 per hr (6 hrs per day) : $30 per day"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="cost1">Estimated cost (@ $1 = &#x20A6;420)</label>
                                            <input id="cost1" name="" type="text" className="form-control" placeholder="" value="" readOnly/>
                                            
                                        </div>
                                        
                                        
                                        <a className="btn btn-primary btn-block btn-loading"  href="whatsapp.me" data-loading-text="Requesting Demo">
                                        contact an agent for discount
                                        </a>
                                        </form>

                                    </div>
                                    </div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-7" data-aos="fade-left">
                            <img src="assets/img/desktop-app/desktop-app-3.jpg" alt="Screenshot" className="img-fluid rounded shadow border"/>
                            </div>
                        </div>
                        </div>
                        <div className="tab-pane fade" id="Personal" role="tabpanel" aria-labelledby="profile-tab">
                        <div className="row align-items-center justify-content-around">
                            <div className="col-lg-6 col-xl-5 text-center text-lg-left mb-4 mb-md-5 mb-lg-0">
                            <div className="pr-lg-4 pr-xl-5" data-aos="fade-right">
                                <h3 className="h3">A Personal website.</h3>
                                <p className="desc">
                                Ned ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
                                </p>
                                <div className="" data-aos="fade-left" data-aos-delay="250">
                                    <div className="card card-body shadow-lg">
                                        <form data-form-email noValidate >
                                        <div className="form-group">
                                            <label htmlFor="project2">Select category</label>
                                            <div className="position-relative">
                                            <select id="project2" onChange={() => getCost('project2','cost2')} className="custom-select" >
                                                <option value="" >Select a plan</option>
                                                <option value="5">Basic (1wks - 5 days per wk) : 5 days</option>
                                                <option value="10">Premium (2wks - 5 days per wk) : 10 days</option>
                                                <option value="15">Pro (3wks - 5 days per wk) : 15 days</option>
                                            </select>
                                            <img src="assets/img/icons/interface/icon-caret-down.svg" alt="Arrow Down" className="icon icon-sm"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="costPerHr">Cost per hour</label>
                                            <input id="costPerHr" name="" type="text" disabled className="form-control" placeholder="" value="$5 per hr (6 hrs per day) : $30 per day"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="cost2">Estimated cost (@ $1 = &#x20A6;420)</label>
                                            <input id="cost2" name="" type="text" className="form-control" placeholder="" value="" readOnly/>
                                            
                                        </div>
                                        
                                        
                                        <a className="btn btn-primary btn-block btn-loading"  href="whatsapp.me" data-loading-text="Requesting Demo">
                                        contact an agent for discount
                                        </a>
                                        </form>

                                    </div>
                                    </div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-7" data-aos="fade-left">
                            <img src="assets/img/desktop-app/desktop-app-1.jpg" alt="Screenshot" className="img-fluid rounded shadow border"/>
                            </div>
                        </div>
                        </div>
                        <div className="tab-pane fade" id="Brand" role="tabpanel" aria-labelledby="contact-tab">
                        <div className="row align-items-center justify-content-around">
                            <div className="col-lg-6 col-xl-5 text-center text-lg-left mb-4 mb-md-5 mb-lg-0">
                            <div className="pr-lg-4 pr-xl-5" data-aos="fade-right">
                                <h3 className="h3">A business brand website.</h3>
                                <p className="desc">
                                Ned ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
                                </p>
                                <div className="" data-aos="fade-left" data-aos-delay="250">
                                    <div className="card card-body shadow-lg">
                                        <form data-form-email noValidate >
                                        <div className="form-group">
                                            <label htmlFor="project3">Select category</label>
                                            <div className="position-relative">
                                            <select id="project3" onChange={() => getCost('project3','cost3')} className="custom-select" >
                                                <option value="" >Select a plan</option>
                                                <option value="10">Basic (2wks - 5 days per wk) : 10 days</option>
                                                <option value="15">Premium (3wks - 5 days per wk) : 15 days</option>
                                                <option value="20">Pro (4wks - 5 days per wk) : 20 days</option>
                                            </select>
                                            <img src="assets/img/icons/interface/icon-caret-down.svg" alt="Arrow Down" className="icon icon-sm"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="costPerHr">Cost per hour</label>
                                            <input id="costPerHr" name="" type="text" disabled className="form-control" placeholder="" value="$5 per hr (6 hrs per day) : $30 per day"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="cost3">Estimated cost (@ $1 = &#x20A6;420)</label>
                                            <input id="cost3" name="" type="text" className="form-control" placeholder="" value="" readOnly/>
                                            
                                        </div>
                                        
                                        
                                        <a className="btn btn-primary btn-block btn-loading"  href="whatsapp.me" data-loading-text="Requesting Demo">
                                        contact an agent for discount
                                        </a>
                                        </form>

                                    </div>
                                    </div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-7" data-aos="fade-left">
                            <img src="assets/img/desktop-app/desktop-app-2.jpg" alt="Screenshot" className="img-fluid rounded shadow border"/>
                            </div>
                        </div>
                        </div>

                        <div className="tab-pane fade" id="anderoid_app" role="tabpanel" aria-labelledby="contact-tab">
                        <div className="row align-items-center justify-content-around">
                            <div className="col-lg-6 col-xl-5 text-center text-lg-left mb-4 mb-md-5 mb-lg-0">
                            <div className="pr-lg-4 pr-xl-5" data-aos="fade-right">
                                <h3 className="h3">An Android App.</h3>
                                <p className="desc">
                                Ned ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
                                </p>
                                <div className="" data-aos="fade-left" data-aos-delay="250">
                                    <div className="card card-body shadow-lg">
                                        <form data-form-email noValidate >
                                        <div className="form-group">
                                            <label htmlFor="project4">Select category</label>
                                            <div className="position-relative">
                                            <select id="project4" onChange={() => getCost('project4','cost4')} className="custom-select" >
                                                <option value="" >Select a plan</option>
                                                <option value="20">Basic (4wks - 5 days per wk) : 20 days</option>
                                                <option value="25">Premium (5wks - 5 days per wk) : 25 days</option>
                                                <option value="30">Pro (6wks - 5 days per wk) : 30 days</option>
                                            </select>
                                            <img src="assets/img/icons/interface/icon-caret-down.svg" alt="Arrow Down" className="icon icon-sm"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="costPerHr">Cost per hour</label>
                                            <input id="costPerHr" name="" type="text" disabled className="form-control" placeholder="" value="$5 per hr (6 hrs per day) : $30 per day"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="cost4">Estimated cost (@ $1 = &#x20A6;420)</label>
                                            <input id="cost4" name="" type="text" className="form-control" placeholder="" value="" readOnly/>
                                            
                                        </div>
                                        
                                        
                                        <a className="btn btn-primary btn-block btn-loading"  href="whatsapp.me" data-loading-text="Requesting Demo">
                                        contact an agent for discount
                                        </a>
                                        </form>

                                    </div>
                                    </div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-7" data-aos="fade-left">
                            <img src="assets/img/desktop-app/desktop-app-2.jpg" alt="Screenshot" className="img-fluid rounded shadow border"/>
                            </div>
                        </div>
                        </div>

                        <div className="tab-pane fade" id="pwa" role="tabpanel" aria-labelledby="contact-tab">
                        <div className="row align-items-center justify-content-around">
                            <div className="col-lg-6 col-xl-5 text-center text-lg-left mb-4 mb-md-5 mb-lg-0">
                            <div className="pr-lg-4 pr-xl-5" data-aos="fade-right">
                                <h3 className="h3">A Progressive Web Application.</h3>
                                <p className="desc">
                                Ned ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
                                </p>
                                <div className="" data-aos="fade-left" data-aos-delay="250">
                                    <div className="card card-body shadow-lg">
                                        <form data-form-email noValidate >
                                        <div className="form-group">
                                            <label htmlFor="project5">Select category</label>
                                            <div className="position-relative">
                                            <select id="project5" onChange={() => getCost('project5','cost5')} className="custom-select" >
                                                <option value="" >Select a plan</option>
                                                <option value="15">Basic (3wks - 5 days per wk) : 15 days</option>
                                                <option value="20">Premium (4wks - 5 days per wk) : 20 days</option>
                                                <option value="25">Pro (5wks - 5 days per wk) : 25 days</option>
                                            </select>
                                            <img src="assets/img/icons/interface/icon-caret-down.svg" alt="Arrow Down" className="icon icon-sm"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="costPerHr">Cost per hour</label>
                                            <input id="costPerHr" name="" type="text" disabled className="form-control" placeholder="" value="$5 per hr (6 hrs per day) : $30 per day"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="cost5">Estimated cost (@ $1 = &#x20A6;420)</label>
                                            <input id="cost5" name="" type="text" className="form-control" placeholder="" value="" readOnly/>
                                            
                                        </div>
                                        
                                        
                                        <a className="btn btn-primary btn-block btn-loading"  href="whatsapp.me" data-loading-text="Requesting Demo">
                                        contact an agent for discount
                                        </a>
                                        </form>

                                    </div>
                                    </div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-7" data-aos="fade-left">
                            <img src="assets/img/desktop-app/desktop-app-2.jpg" alt="Screenshot" className="img-fluid rounded shadow border"/>
                            </div>
                        </div>
                        </div>

                        <div className="tab-pane fade" id="advertisement" role="tabpanel" aria-labelledby="contact-tab">
                        <div className="row align-items-center justify-content-around">
                            <div className="col-lg-6 col-xl-5 text-center text-lg-left mb-4 mb-md-5 mb-lg-0">
                            <div className="pr-lg-4 pr-xl-5" data-aos="fade-right">
                                <h3 className="h3">An advertisement website.</h3>
                                <p className="desc">
                                Ned ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
                                </p>
                                <div className="" data-aos="fade-left" data-aos-delay="250">
                                    <div className="card card-body shadow-lg">
                                        <form data-form-email noValidate >
                                        <div className="form-group">
                                            <label htmlFor="project6">Select category</label>
                                            <div className="position-relative">
                                            <select id="project6" onChange={() => getCost('project6','cost6')} className="custom-select" >
                                                <option value="" >Select a plan</option>
                                                <option value="10">Basic (2wks - 5 days per wk) : 10 days</option>
                                                <option value="15">Premium (3wks - 5 days per wk) : 15 days</option>
                                                <option value="20">Pro (4wks - 5 days per wk) : 20 days</option>
                                            </select>
                                            <img src="assets/img/icons/interface/icon-caret-down.svg" alt="Arrow Down" className="icon icon-sm"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="costPerHr">Cost per hour</label>
                                            <input id="costPerHr" name="" type="text" disabled className="form-control" placeholder="" value="$5 per hr (6 hrs per day) : $30 per day"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="cost6">Estimated cost (@ $1 = &#x20A6;420)</label>
                                            <input id="cost6" name="" type="text" className="form-control" placeholder="" value="" readOnly/>
                                            
                                        </div>
                                        
                                        
                                        <a className="btn btn-primary btn-block btn-loading"  href="whatsapp.me" data-loading-text="Requesting Demo">
                                        contact an agent for discount
                                        </a>
                                        </form>

                                    </div>
                                    </div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-7" data-aos="fade-left">
                            <img src="assets/img/desktop-app/desktop-app-2.jpg" alt="Screenshot" className="img-fluid rounded shadow border"/>
                            </div>
                        </div>
                        </div>

                        <div className="tab-pane fade" id="entertainment" role="tabpanel" aria-labelledby="contact-tab">
                        <div className="row align-items-center justify-content-around">
                            <div className="col-lg-6 col-xl-5 text-center text-lg-left mb-4 mb-md-5 mb-lg-0">
                            <div className="pr-lg-4 pr-xl-5" data-aos="fade-right">
                                <h3 className="h3">An entertainment website.</h3>
                                <p className="desc">
                                Ned ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.
                                </p>
                                <div className="" data-aos="fade-left" data-aos-delay="250">
                                    <div className="card card-body shadow-lg">
                                        <form data-form-email noValidate >
                                        <div className="form-group">
                                            <label htmlFor="project7">Select category</label>
                                            <div className="position-relative">
                                            <select id="project7" onChange={() => getCost('project7','cost7')} className="custom-select" >
                                                <option value="" >Select a plan</option>
                                                <option value="15">Basic (3wks - 5 days per wk) : 15 days</option>
                                                <option value="20">Premium (4wks - 5 days per wk) : 20 days</option>
                                                <option value="25">Pro (4wks - 5 days per wk) : 25 days</option>
                                            </select>
                                            <img src="assets/img/icons/interface/icon-caret-down.svg" alt="Arrow Down" className="icon icon-sm"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="costPerHr">Cost per hour</label>
                                            <input id="costPerHr" name="" type="text" disabled className="form-control" placeholder="" value="$5 per hr (6 hrs per day) : $30 per day"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="cost7">Estimated cost (@ $1 = &#x20A6;420)</label>
                                            <input id="cost7" name="" type="text" className="form-control" placeholder="" value="" readOnly/>
                                            
                                        </div>
                                        
                                        
                                        <a className="btn btn-primary btn-block btn-loading"  href="whatsapp.me" data-loading-text="Requesting Demo">
                                        contact an agent for discount
                                        </a>
                                        </form>

                                    </div>
                                    </div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-7" data-aos="fade-left">
                            <img src="assets/img/desktop-app/desktop-app-2.jpg" alt="Screenshot" className="img-fluid rounded shadow border"/>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}