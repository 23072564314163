import React from 'react';

const faqH3 = {
    marginTop: '100px',
    color: '#2C3038',
    marginBottom: '60px',
    fontFamily: 'QuicksandB',
}

export default function Questions() {
    return(
        <section className="p-0 bg-primary">
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-md-10 col-lg-8">
                    <h3 className="display-4" style={faqH3}>FAQ</h3>
                    <div id="faq-accordion">
                    <div className="card mb-2 mb-md-3">
                        <a href="#accordion-1" data-toggle="collapse" role="button" aria-expanded="false" className="p-3 p-md-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="mb-0 mr-2">How much does it cost to build an E-commerce platfom?</h6>
                            <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" className="icon icon-sm" data-inject-svg/>
                        </div>
                        </a>
                        <div className="collapse" id="accordion-1" data-parent="#faq-accordion">
                        <div className="px-3 px-md-4 pb-3 pb-md-4">
                            Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre. Vivamus ornare pharetra diam sit amet tincidunt. Eunuch sit amet pharetra odio. Vivamus in tempor ipsum, sit amet elementum neque. Sed faucibus posuere pharetra.
                            In imperdiet eleifend dui a aliquet. Aliquam imperdiet Tyrion purus vitae rutrum. Donec eu commodo nunc. Mauris dignissim lectus massa, eget cursus quam mollis id. Eddard sit amet ex Jon nibh maximus cursus at vitae mi. Duis tincidunt
                            aliquam mi sed sagittis.

                        </div>
                        </div>
                    </div>
                    <div className="card mb-2 mb-md-3">
                        <a href="#accordion-2" data-toggle="collapse" role="button" aria-expanded="false" className="p-3 p-md-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="mb-0 mr-2">How much does it cost to develop an android app?</h6>
                            <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" className="icon icon-sm" data-inject-svg/>
                        </div>
                        </a>
                        <div className="collapse" id="accordion-2" data-parent="#faq-accordion">
                        <div className="px-3 px-md-4 pb-3 pb-md-4">
                            Eunuch nec dapibus ex. Aenean placerat, ex imp convallis dictum, ex nulla rutrum justo, Jon lobortis nisi ex at leo. Sed Tyrion aliquet sem vel pharetra. Vestibulum ante ipsum primis in faucibus Hodor luctus et ultrices posuere cubilia Curae; Class aptent
                            taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Duis a sapien quis quam auctor feugiat. Donec volutpat condimentum risus, eu iaculis nibh dapibus eu.

                        </div>
                        </div>
                    </div>
                    <div className="card mb-2 mb-md-3">
                        <a href="#accordion-3" data-toggle="collapse" role="button" aria-expanded="false" className="p-3 p-md-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="mb-0 mr-2">How long does your software engineering trainign last?</h6>
                            <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" className="icon icon-sm" data-inject-svg/>
                        </div>
                        </a>
                        <div className="collapse" id="accordion-3" data-parent="#faq-accordion">
                        <div className="px-3 px-md-4 pb-3 pb-md-4">
                            Volantisi fringilla, unuch eu sagittis sagittis, urna Loras luctus odio, vitae hendrerit massa dui ac est. Donec leo tortor, Tyrion et aliquet at, convallis imp mi. Vivamus turpis diam, ultrices et tempus quis, sollicitudin et risus. Pellentesque nec
                            sapien imp dolor condimentum condimentum ut sed neque. Integer efficitur accumsan risus, vitae posuere massa aliquam at.

                        </div>
                        </div>
                    </div>
                    <div className="card mb-2 mb-md-3">
                        <a href="#accordion-4" data-toggle="collapse" role="button" aria-expanded="false" className="p-3 p-md-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="mb-0 mr-2">How can i learn how to develop a website?</h6>
                            <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" className="icon icon-sm" data-inject-svg/>
                        </div>
                        </a>
                        <div className="collapse" id="accordion-4" data-parent="#faq-accordion">
                        <div className="px-3 px-md-4 pb-3 pb-md-4">
                            Khaleesi ornare pharetra diam sit amet tincidunt. Eunuch sit amet pharetra odio. Vivamus in tempor ipsum, sit amet elementum neque. Sed faucibus posuere pharetra. In imperdiet eleifend dui a aliquet. Aliquam imperdiet Tyrion purus vitae rutrum. Donec
                            eu commodo nunc. Vivamus Melisandre Jon lorem eget bibendum. Sed tincidunt sed enim at dignissim. Mauris erat diam, lacinia eget efficitur et, iaculis sed augue.

                        </div>
                        </div>
                    </div>
                    <div className="card mb-2 mb-md-3">
                        <a href="#accordion-5" data-toggle="collapse" role="button" aria-expanded="false" className="p-3 p-md-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="mb-0 mr-2">What do i need to start training with Geneus?</h6>
                            <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" className="icon icon-sm" data-inject-svg/>
                        </div>
                        </a>
                        <div className="collapse" id="accordion-5" data-parent="#faq-accordion">
                        <div className="px-3 px-md-4 pb-3 pb-md-4">
                            Brienne ac maximus Loras, eu placerat odio. Etiam vestibulum Loras et sollicitudin pellentesque. Mauris sed Tyrion Varys. Curabitur posuere augue risus, eget mollis unuch consectetur quis. Vestibulum accumsan congue risus, in semper eros interdum id.
                            Tincidunt vitae libero efficitur viverra. Integer venenatis massa in dui viverra fermentum. Eunuch fringilla arcu ac urna sodales fermentum. Ut luctus enim ut sagittis consectetur.

                        </div>
                        </div>
                    </div>
                    <div className="card mb-2 mb-md-3">
                        <a href="#accordion-6" data-toggle="collapse" role="button" aria-expanded="false" className="p-3 p-md-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="mb-0 mr-2">What is the cost of Digital Marketing Training?</h6>
                            <img src="assets/img/icons/interface/icon-caret-right.svg" alt="Caret Right" className="icon icon-sm" data-inject-svg/>
                        </div>
                        </a>
                        <div className="collapse" id="accordion-6" data-parent="#faq-accordion">
                        <div className="px-3 px-md-4 pb-3 pb-md-4">
                            Brienne ac maximus Loras, eu placerat odio. Etiam vestibulum Loras et sollicitudin pellentesque. Mauris sed Tyrion Varys. Curabitur posuere augue risus, eget mollis unuch consectetur quis. Vestibulum accumsan congue risus, in semper eros interdum id.

                        </div>
                        </div>
                    </div>
                    </div>

                </div>
                </div>
                <div className="row justify-content-center mt-4 mt-md-5">
                <div className="col-auto">
                    <div className="alert text-white links-white bg-white-alt">Is there something we didn't cover here? <a title="call us now" href="tel:1-562-867-5309">Call us now</a> or <a title="mail us now" href="mailto:example@gmail.com">Send us a mail</a> 
                    </div>
                </div>
                </div>
            </div>
            <div className="divider divider-bottom bg-primary-3"></div>
        </section>
    );
}