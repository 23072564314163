import React from 'react';

import {
    // BrowserRouter as Router,
    // Switch,
    // Route,
    Link
    } from "react-router-dom";
     
        const linkColor = {
          color: '#fff',
        }

        const hideIcon = {
          display: 'none',
          color: '#FFE69B',
          cursor: 'pointer',
        }

        const brandLogo = {
          width:'40px',
          height:'40px',
        }

        const hideIconText = {
          fontSize: '25px',
        }
        
        const overlayDiv = {
          position: 'fixed',
          display: 'none',
          width: '100%',
          height: '100%',
          top:'0',
          left:'0',
          right:'0',
          bottom:'0',
          backgroundColor: 'rgba(0,0,0,0.95)',
          zIndex: '2',
        }
       
        const overlayText ={
          position: 'absolute',
          top: '50%',
          left: '50%',
          fontSize: '60px',
          color: 'white',
          transform: 'translate(-50%,-50%)',
          msTransform: 'translate(-50%,-50%)',
          textAlign: 'center',
        }

        function on() {
          document.getElementById("overlay").style.display = "block";
          document.getElementById("navIconMenu").style.display = "none";
          document.getElementById("navLeft").style.display = "none";
          document.getElementById("navIconClose").style.display = "block";
          document.getElementById("overlayTextH1").style.display = "block";
          document.getElementById("overlayTextH1").classList.add('bounceInRight');
          document.getElementById("logoImg").classList.add('logoImg');
        }


        function off() {
          document.getElementById("overlay").style.display = "none";
          document.getElementById("navIconMenu").style.display = "inline-block";
          document.getElementById("navLeft").style.display = "block";
          document.getElementById("navIconClose").style.display = "none";
          document.getElementById("overlayTextH1").classList.remove('bounceInRight');
          document.getElementById("overlayTextH1").style.display = "none";
          document.getElementById("logoImg").classList.remove('logoImg');

        }
        

        
        export default function Header() {
        return (

          <div>
            <div className="navbar-container"  >
                    <nav className="navbar navbar-light position-fixed nav-padding" id="header" data-sticky="top" >
                      {/* <!-- Brand --> */}
                      {/* <Link  to="/">
                      <img  style={brandLogo}  alt="Logo" src="favicon.ico"/>
                      </Link> */}
                      <a id="logoImg" className="navbar-brand" href="home">
                        <img  style={brandLogo}  alt="Logo" src="favicon.ico"/>
                      </a>

                        <div id="navLeft" >
                          {/* <a title="call us now" className="fa fa-phone" href="tel:1-562-867-5309"></a>  */}
                          {/* <a title="mail us now" className="fa fa-envelope" href="mailto:example@gmail.com"></a> */}
                          <span id="navIconMenu" onClick={on} className="fa fa-navicon"></span>
                        </div>

                        <div id="overlay" style={overlayDiv} >
                          <div id="text" style={overlayText} >
                              
                            <span id="navIconClose" onClick={off}  style={hideIcon}>&times; <span style={hideIconText}>close</span></span>

                            <h2 style={linkColor} id="overlayTextH1" className="display-4">Know more <mark data-aos="highlight-text" data-aos-delay="500" className="aos-init aos-animate"><Link onClick={off} to="/about">about us</Link></mark>, Tour <mark data-aos="highlight-text" data-aos-delay="500" className="aos-init aos-animate"><Link onClick={off} to="/works">our works</Link></mark>, Check out <mark data-aos="highlight-text" data-aos-delay="500" className="aos-init aos-animate"><Link onClick={off} to="/pricing">our Pricing</Link></mark> and do not forget to <mark data-aos="highlight-text" data-aos-delay="500" className="aos-init aos-animate"><Link onClick={off} to="/contact">contact us</Link></mark>.</h2>

                          </div>
                        </div>
                    </nav>                       
                </div>
          </div>
        );
        }
        
        
    
