import React from 'react';

const cardBodyMargin = {
    marginTop: '60px',
}

export default function About() {
    return(
        <section className="bg-primary about">
            <div className="container">
                <div className="text-white">
                <div className="row section-title justify-content-center text-center">
                    <div className="col-md-9 col-lg-8 col-xl-7">
                    <h3 className="display-4">Who We Are</h3>
                    <div className="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.</div>
                    </div>
                </div>
                </div>
                <div className="row align-items-center justify-content-center">
                <div className="col-sm-9 col-md-7 col-lg-4 mb-3 mb-md-4 mb-lg-0" data-aos="fade-up" data-aos-delay="100">
                    <div className="card card-body align-items-center shadow">
                        <div data-aos="fade-in" data-aos="fade-in">
                            <img src="assets/img/square-2.jpg" alt="what we do" className="img-fluid rounded shadow"/>
                        </div>
                    </div>
                </div>
                <div className="col-sm-9 col-md-7 col-lg-4 mb-3 mb-md-4 mb-lg-0" data-aos="fade-up" data-aos-delay="200" style={cardBodyMargin}>
                    <div className="card card-body align-items-center shadow">
                        <div data-aos="fade-in" data-aos="fade-in">
                            <img src="assets/img/square-2.jpg" alt="what we do" className="img-fluid rounded shadow"/>
                        </div>
                    </div>
                </div>
                <div className="col-sm-9 col-md-7 col-lg-4 mb-3 mb-md-4 mb-lg-0" data-aos="fade-up" data-aos-delay="300">
                <div className="card card-body align-items-center shadow">
                    <div data-aos="fade-in" data-aos="fade-in">
                        <img src="assets/img/square-2.jpg" alt="what we do" className="img-fluid rounded shadow"/>
                    </div>
                </div>
                </div>
                <div className="col-sm-9 col-md-7 col-lg-4 mb-3 mb-md-4 mb-lg-0" data-aos="fade-up" data-aos-delay="300">
                <div className="card card-body align-items-center shadow">
                    <div data-aos="fade-in" data-aos="fade-in">
                        <img src="assets/img/square-2.jpg" alt="what we do" className="img-fluid rounded shadow"/>
                    </div>
                </div>
                </div>
                <div className="col-sm-9 col-md-7 col-lg-4 mb-3 mb-md-4 mb-lg-0" data-aos="fade-up" data-aos-delay="300" style={cardBodyMargin}>
                <div className="card card-body align-items-center shadow">
                    <div data-aos="fade-in" data-aos="fade-in">
                        <img src="assets/img/square-2.jpg" alt="what we do" className="img-fluid rounded shadow"/>
                    </div>
                </div>
                </div>
                <div className="col-sm-9 col-md-7 col-lg-4 mb-3 mb-md-4 mb-lg-0" data-aos="fade-up" data-aos-delay="300">
                <div className="card card-body align-items-center shadow">
                    <div data-aos="fade-in" data-aos="fade-in">
                        <img src="assets/img/square-2.jpg" alt="what we do" className="img-fluid rounded shadow"/>
                    </div>
                </div>
                </div>
                </div>
            </div>
        </section>
    );
}