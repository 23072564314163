import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
  } from "react-router-dom";


import Home from './Home/components/Home';
import pricingPage from './Home/components/pricingPage';
import AboutPage from './Home/components/AboutPage.js';
import PortfolioPage from './Home/components/PortfolioPage.js';
import ContactPage from './Home/components/ContactPage.js';
import ErrorPage from './Home/components/ErrorPage.js';



export default function App() {
  return (
    <Router>        
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/index" exact component={Home} />
        <Route path="/index.html" exact component={Home} />
        <Route path="/Home" exact component={Home} />
        <Route path="/about"  component={AboutPage} />
        <Route path="/works"  component={PortfolioPage }/>
        <Route path="/pricing" component={pricingPage} /> 
        <Route path="/contact" component={ContactPage} /> 
        <Route  component={ErrorPage} /> 
      </Switch>
    </Router>
  )
}

