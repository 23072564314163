import React from 'react';

const btnSpacing ={
    width: '100%',
    textAlign: 'center',
    display: 'inline-block',
    // padding: '15px',
}

export default function Hero() {
    return(
        
        <section className="pb-0 o-hidden hero">
            <div className="container">
                <div className="row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left flex-lg-row-reverse">
                    <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-lg-0 pr-lg-5 pr-xl-0">
                        <div data-aos="fade-in" data-aos-delay="250" className="aos-init aos-animate">
                            <h1 className="display-4" id="heading">Geneus <mark data-aos="highlight-text" data-aos-delay="500" className="aos-init aos-animate">Creates</mark>, <mark data-aos="highlight-text" data-aos-delay="500" className="aos-init aos-animate">Designs</mark> and <mark data-aos="highlight-text" data-aos-delay="500" className="aos-init aos-animate">Builds</mark> Digital Products</h1>
                            <h3 className="display-5" id="sub-heading">We also <mark data-aos="highlight-text" data-aos-delay="500" className="aos-init aos-animate">Train</mark> you into a <mark data-aos="highlight-text" data-aos-delay="500" className="aos-init aos-animate"> Software Engineer</mark>, <mark data-aos="highlight-text" data-aos-delay="500" className="aos-init aos-animate"> Digital Marketer</mark> and <mark data-aos="highlight-text" data-aos-delay="500" className="aos-init aos-animate">more...</mark>.
                            </h3>
                            <div className="mt-4 mt-md-5">                                    
                                <a className="btn btn-primary flex-shrink-0 btn-block" href="tel:1-562-867-5309" >Call Us Now</a>
                                    <span style={btnSpacing}></span>
                                <a className="btn btn-block" href="mailto:example@gmail.com" >or shoot us a mail</a>
                            </div>    
                        </div>
                    </div>
                    <div className="col-md-9 col-lg-6 aos-init aos-animate" data-aos="fade-left">
                        <img src="assets/img/illustrations/illustration-1.svg" alt="hero banner" className="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
        
    );
}