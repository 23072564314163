import React from 'react';


export default function PortfolioHeader() {
    return(
        <div className="pt-50">
            <div className="row section-title justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-7">
                <h3 className="display-4">Featured Projects</h3>
                <div className="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.</div>
            </div>
        </div>
        </div>
        
    );
}