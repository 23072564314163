import React from 'react';

const  paddingBottom = {
    float: 'left',
    paddingBottom : '80px',
}


export default function Portfolio() {
    return(
        <section className="o-hidden" id="portfolio">
            {/* <h2 className="display-4 p-5">Our Works</h2> */}
      <div className="container">
        <div  className="row align-items-center justify-content-around text-center text-lg-left float-left">
            <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
                <div>
                    
                <div className="portfolio-image">
                    <a className="btn btn-block" href="#" >
                        <img src="assets/img/desktop-app/desktop-app-1.jpg" alt="Our work" className="img-fluid"/>
                    </a>
                </div>
                
                    <div style={paddingBottom}>
                        <h3 className="display-5">Lorem</h3>
                        <p className="lead">Lorem ipsum quint et in sum</p>
                    </div>

                </div>
            </div>

            <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
                <div>
                    
                <div className="portfolio-image">
                    <a className="btn btn-block" href="#" >
                        <img src="assets/img/illustrations/illustration-2.svg" alt="Our work" className="img-fluid"/>
                    </a>
                </div>
                
                    <div style={paddingBottom}>
                        <h3 className="display-5">Lorem</h3>
                        <p className="lead">Lorem ipsum quint et in sum</p>
                    </div>

                </div>
            </div>

            <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
                <div>
                    
                <div className="portfolio-image">
                    <a className="btn btn-block" href="#" >
                        <img src="assets/img/desktop-app/desktop-app-2.jpg" alt="Our work" className="img-fluid"/>
                    </a>
                </div>
                
                    <div style={paddingBottom}>
                        <h3 className="display-5">Lorem</h3>
                        <p className="lead">Lorem ipsum quint et in sum</p>
                    </div>

                </div>
            </div>

            <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0  hide-mobile">
                <div>
                    
                <div className="portfolio-image">
                    <a className="btn btn-block" href="#" >
                        <img src="assets/img/desktop-app/desktop-app-3.jpg" alt="Our work" className="img-fluid"/>
                    </a>
                </div>
                
                    <div style={paddingBottom}>
                        <h3 className="display-5">Lorem</h3>
                        <p className="lead">Lorem ipsum quint et in sum</p>
                    </div>

                </div>
            </div>

            <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0 hide-mobile">
                <div>
                    
                <div className="portfolio-image">
                    <a className="btn btn-block" href="#" >
                        <img src="assets/img/illustrations/illustration-2.svg" alt="Our work" className="img-fluid"/>
                    </a>
                </div>
                
                    <div style={paddingBottom}>
                        <h3 className="display-5">Lorem</h3>
                        <p className="lead">Lorem ipsum quint et in sum</p>
                    </div>

                </div>
            </div>

            <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0 hide-mobile">
                <div>
                    
                <div className="portfolio-image">
                    <a className="btn btn-block" href="#" >
                        <img src="assets/img/desktop-app/desktop-app-2.jpg" alt="Our work" className="img-fluid"/>
                    </a>
                </div>
                
                    <div style={paddingBottom}>
                        <h3 className="display-5">Lorem</h3>
                        <p className="lead">Lorem ipsum quint et in sum</p>
                    </div>

                </div>
            </div>

            <div id="accordion">
                            
                <div id="collapseOne" className="collapse hide" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
                        <div>
                            
                        <div className="portfolio-image">
                            <a className="btn btn-block" href="#" >
                                <img src="assets/img/desktop-app/desktop-app-3.jpg" alt="Our work" className="img-fluid"/>
                            </a>
                        </div>
                        
                            <div>
                                <h3 className="display-5">Lorem</h3>
                                <p className="lead">Lorem ipsum quint et in sum</p>
                            </div>

                        </div>
                    </div>

                    

                </div>
            <div className="card-header" id="headingOne">
                    <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" id="collapseOneBtn" onClick={change_text}>see more</button> <span className="fa fa-arrow-down" id="arrow-down"></span>
            </div>

        </div>
        
        </div>
      </div>
    </section>
        );

        var collapsed = true;
        function change_text() {

            if (collapsed == null || collapsed == true) {
                console.log('hi');
                document.getElementById("collapseOneBtn").innerHTML = 'see less';
                document.getElementById("arrow-down").className = 'fa fa-arrow-up';
                collapsed = false;
            } else {
                console.log('hello');
                document.getElementById("collapseOneBtn").innerHTML = 'see more';
                document.getElementById("arrow-down").className = 'fa fa-arrow-down';
                collapsed = true;
            }
            
        }
}