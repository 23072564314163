import React from 'react';

import Portfolio from './Portfolio';
import Header from './Header';
import PortfolioHeader from './PortfolioHeader';



export default function PortfolioPage() {
    return(
        <div>
            <Header/>
            <PortfolioHeader/>
            <Portfolio/>
        </div>
        
    );
}