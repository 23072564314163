import React from 'react';
import About from './About';
import Header from './Header';


export default function AboutPage() {
    return(
      <div>
        <div className="bg-primary-3 o-hidden" data-overlay>
        <Header/>
        <section className="text-white pb-0">
          <div className="container pb-5">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 c ol-lg-8 col-xl-7">
                <h1 className="display-3">A feisty group of <mark data-aos="highlight-text" data-aos-delay="250">change makers</mark></h1>
                <p className="lead mb-0">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.</p>

              </div>
            </div>
          </div>
          <div className="position-absolute w-50 h-100 top left" data-jarallax-element="100 50">
            <div className="blob blob-4 bg-gradient w-100 h-100 top left"></div>
          </div>
          <div className="divider divider-bottom bg-white"></div>
        </section>
        </div>
        <section className="pb-0">
          <div className="container">
            <div className="row section-title justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-7">
                <h3 className="display-4">Less work, <mark data-aos="highlight-text" data-aos-delay="250">more flow.</mark></h3>
                <div className="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.</div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col">
                <ul className="avatar-group avatar-group-lg flex-wrap justify-content-center">
                  <li>
                    <img src="assets/img/avatars/male-1.jpg" alt="Joshua Lapinsky profile " className="avatar avatar-xl"/>
                  </li>
                  <li>
                    <img src="assets/img/avatars/female-1.jpg" alt="Glenda Spence profile " className="avatar avatar-xl"/>
                  </li>
                  <li>
                    <img src="assets/img/avatars/male-3.jpg" alt="Patrik Janssen profile " className="avatar avatar-xl"/>
                  </li>
                  <li>
                    <img src="assets/img/avatars/female-2.jpg" alt="Mirembe Nkrumah profile " className="avatar avatar-xl"/>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row justify-content-center mt-4 mt-md-5">
              <div className="col-auto">
                {/* <div className="alert bg-secondary">Interested in joining the team? 
                  <a href="#">View Careers</a>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row align-items-center justify-content-around text-center text-lg-left">
              <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 order-lg-2" data-aos="fade-in">
                <h2 className="h1">We’re an aspiring team of coders and creatives</h2>
                <p className="lead">Berspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                <a href="#" className="lead">Check this out</a>
              </div>
              <div className="col-md-9 col-lg-6 col-xl-5 order-lg-1" data-aos="fade-in">
                <img src="assets/img/about-2.jpg" alt="" className="img-fluid rounded shadow"/>
                <img src="assets/img/about-2a.jpg" alt="" className="position-absolute p-0 col-4 col-xl-5 border border-white border-thick rounded-circle top left shadow-lg mt-5 ml-n5 ml-lg-n3 ml-xl-n5 d-none d-md-block" data-jarallax-element="-20 0"/>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-0 o-hidden">
          <div className="container">
            <div className="row align-items-center justify-content-around text-center text-lg-left">
              <div className="col-md-9 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0 pl-lg-5 pl-xl-0">
                <div data-aos="fade-in" data-aos-offset="250">
                  <h2 className="h1">We forge partnerships and build great products</h2>
                  <p className="lead">Berspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                </div>
                <div className="d-flex flex-wrap justify-content-center justify-content-lg-start">
                  <div className="mb-3 mr-4 ml-lg-0 mr-lg-4" data-aos="fade-left" data-aos-delay="100">
                    <div className="d-flex align-items-center">
                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Binoculars icon" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>
                      <h6 className="mb-0 ml-3">Fully Responsive</h6>
                    </div>
                  </div>
                  <div className="mb-3 mr-4 ml-lg-0 mr-lg-4" data-aos="fade-left" data-aos-delay="200">
                    <div className="d-flex align-items-center">
                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Layouts icon" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>
                      <h6 className="mb-0 ml-3">Multiple Layouts</h6>
                    </div>
                  </div>
                  <div className="mb-3 mr-4 ml-lg-0 mr-lg-4" data-aos="fade-left" data-aos-delay="300">
                    <div className="d-flex align-items-center">
                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Box icon" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>
                      <h6 className="mb-0 ml-3">Modular Components</h6>
                    </div>
                  </div>
                  <div className="mb-3 mr-4 ml-lg-0 mr-lg-4" data-aos="fade-left" data-aos-delay="400">
                    <div className="d-flex align-items-center">
                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Lightning icon" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>
                      <h6 className="mb-0 ml-3">Suits Your Style</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-6 col-xl-5" data-aos="fade-in" data-aos-offset="250">
                <img src="assets/img/about-1.jpg" alt="" className="img-fluid rounded shadow"/>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-primary-3 text-white pt-0">
          <div className="divider divider-top transform-flip-x bg-light"></div>
          <div className="container">
            <div className="row section-title justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-7">
                <h3 className="display-4">Here are the numbers</h3>
                <div className="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-3 mb-md-0">
                <div className="card card-body bg-white h-100">
                  <div className="display-4 text-primary mb-3" data-countup data-start="" data-end="97" data-duration="" data-decimal-places="" data-prefix="" data-separator="" data-grouping="" data-suffix="%" data-easing=""></div>
                  <div>Average satisfaction rating received in the past year</div>
                </div>
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <div className="card card-body bg-white h-100">
                  <div className="display-4 text-primary mb-3" data-countup data-start="20" data-end="24" data-duration="1" data-decimal-places="" data-prefix="" data-separator="" data-grouping="" data-suffix="/7" data-easing="false"></div>
                  <div>Our support experts are ready to assist anytime</div>
                </div>
              </div>
              <div className="col-md-4 mb-3 mb-md-0">
                <div className="card card-body bg-white h-100">
                  <div className="display-4 text-primary mb-3" data-countup data-start="" data-end="142" data-duration="" data-decimal-places="" data-prefix="" data-separator="" data-grouping="" data-suffix="k+" data-easing=""></div>
                  <div>App installs from the two major mobile app stores</div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <About/>
        </div>
    );
}