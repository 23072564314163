import React from 'react';

import Header from './Header';



export default function ErrorPage() {
    return(
        <div>
            <section className="bg-primary-3 text-white p-0 o-hidden errorPage">
            <Header/>
            <div className="container min-vh-100 d-flex flex-column justify-content-between text-center py-4 py-md-5">
                <a href="index.html" className="fade-page">
                <img src="assets/img/logos/jumpstart.svg" alt="Jumpstart" className="bg-white" data-inject-svg/>
                </a>
                <div className="my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <h1>404 - Page Not Found</h1>
                        <div className="lead">Whoops, it looks like the page you request wasn't found.</div>
                    </div>
                    <div className="col-5 mb-4">
                    <img src="assets/img/illustrations/illustration-4.svg" alt="404 Page Not Found" className="img-fluid"/>
                    </div>
                </div>
                </div>
                <div>
                <a className="btn btn-sm btn-outline-light fade-page" href="home">Back to Home</a>
                </div>
            </div>
            </section>

            <a href="#top" className="btn btn-primary rounded-circle btn-back-to-top" data-smooth-scroll data-aos="fade-up" data-aos-offset="2000" data-aos-mirror="true" data-aos-once="false">
            <img src="assets/img/icons/interface/icon-arrow-up.svg" alt="Icon" className="icon bg-white" data-inject-svg/>
            </a>
        </div>
        
    );
}