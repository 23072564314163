import React from 'react';
import Header from './Header';
import Pricing from './Pricing';


export default function pricingPage() {
    return(
      <div>
        <div className="bg-primary-3 text-white o-hidden" data-overlay>
          <Header/>
          <section className="pb-0">
            <div className="container pb-5">
              <div className="row text-center justify-content-center">
                <div className="col">
                  <h1 className="display-3">Pricing that <mark>scales</mark> with you</h1>
                </div>
              </div>
              <div className="row text-center justify-content-center">
                <div className="col-md-9 col-lg-8 col-xl-7">
                  <p className="lead">Berspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                </div>
              </div>
            </div>
            <div className="position-absolute w-100 h-100 bottom right" data-jarallax-element="100">
              <div className="blob blob-4 bg-gradient top left w-100 h-100"></div>
            </div>
            <div className="divider divider-bottom"></div>
          </section>
          
        </div>
        <Pricing/>
        <section className="pt-5">
          <div className="container">
            <div className="row justify-content-end sticky-top bg-white">
              <div className="col-lg-3 col-4 py-3 py-md-4 border-bottom">
                <div className="d-flex flex-column align-items-center text-center">
                  <h4>Basic</h4>
                  <div className="d-flex align-items-center justify-content-center mb-1">
                    <span className="h5 mb-0 mr-1 mr-sm-2">$</span>
                    <span className="display-4 mb-0 text-dark">0</span>
                  </div>
                  <div className="text-small mb-3 mb-md-4">Per user, per month</div>
                  <a href="#" className="btn btn-outline-primary btn-block">
                    <span>Go</span>
                    <span className="d-none d-md-inline-block">Basic</span>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-4 py-3 py-md-4 border-bottom">
                <div className="d-flex flex-column align-items-center text-center">
                  <h4>Premium</h4>
                  <div className="d-flex align-items-center justify-content-center mb-1">
                    <span className="h5 mb-0 mr-1 mr-sm-2">$</span>
                    <span className="display-4 mb-0 text-dark">29</span>
                  </div>
                  <div className="text-small mb-3 mb-md-4">Per user, per month</div>
                  <a href="#" className="btn btn-primary btn-block">
                    <span>Go</span>
                    <span className="d-none d-md-inline-block">Premium</span>
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-4 py-3 py-md-4 border-bottom">
                <div className="d-flex flex-column align-items-center text-center">
                  <h4>Pro</h4>
                  <div className="d-flex align-items-center justify-content-center mb-1">
                    <span className="h5 mb-0 mr-1 mr-sm-2">$</span>
                    <span className="display-4 mb-0 text-dark">49</span>
                  </div>
                  <div className="text-small mb-3 mb-md-4">Per user, per month</div>
                  <a href="#" className="btn btn-outline-primary btn-block">
                    <span>Go</span>
                    <span className="d-none d-md-inline-block">Pro</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="pricing-table-section text-center text-lg-left">
                <div className="row no-gutters">
                  <div className="col">
                    <h5 className="mb-4">Basic Features</h5>
                  </div>
                </div>
                <div className="border rounded">
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Projects</h6>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>4</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>Unlimited</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>Unlimited</span>

                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Client Billing</h6>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Restricted Access</h6>
                        <div className="ml-2 tooltip-circle bg-dark text-white" data-toggle="tooltip" title="Here's a tooltip to help explain this feature">?</div>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-danger-alt">
                        <img src="assets/img/icons/interface/icon-x.svg" alt="Included" className="m-2 icon icon-xs bg-danger" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>Enhanced</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>Custom</span>

                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Code Export</h6>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-danger-alt">
                        <img src="assets/img/icons/interface/icon-x.svg" alt="Included" className="m-2 icon icon-xs bg-danger" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Project Transfers</h6>
                        <div className="ml-2 tooltip-circle bg-dark text-white" data-toggle="tooltip" title="Here's a tooltip to help explain this feature">?</div>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-danger-alt">
                        <img src="assets/img/icons/interface/icon-x.svg" alt="Included" className="m-2 icon icon-xs bg-danger" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>Unlimited</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>Unlimited</span>

                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing-table-section text-center text-lg-left">
                <div className="row no-gutters">
                  <div className="col">
                    <h5 className="mb-4">Hosting Features</h5>
                  </div>
                </div>
                <div className="border rounded">
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Hosting Domains</h6>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>1</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>100</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>Unlimited</span>

                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Domain Aliases</h6>
                        <div className="ml-2 tooltip-circle bg-dark text-white" data-toggle="tooltip" title="Here's a tooltip to help explain this feature">?</div>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-danger-alt">
                        <img src="assets/img/icons/interface/icon-x.svg" alt="Included" className="m-2 icon icon-xs bg-danger" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Bandwidth Allowance</h6>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>20GB</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>Unlimited</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>Unlimited</span>

                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Analytics</h6>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-danger-alt">
                        <img src="assets/img/icons/interface/icon-x.svg" alt="Included" className="m-2 icon icon-xs bg-danger" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-danger-alt">
                        <img src="assets/img/icons/interface/icon-x.svg" alt="Included" className="m-2 icon icon-xs bg-danger" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="pricing-table-section text-center text-lg-left">
                <div className="row no-gutters">
                  <div className="col">
                    <h5 className="mb-4">E-Commerce Features</h5>
                  </div>
                </div>
                <div className="border rounded">
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Products</h6>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>15</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>Unlimited</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>Unlimited</span>

                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Custom Checkout</h6>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Transaction Fee</h6>
                        <div className="ml-2 tooltip-circle bg-dark text-white" data-toggle="tooltip" title="Here's a tooltip to help explain this feature">?</div>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>5.10%</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>3.30%</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>1.25%</span>

                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Analytics</h6>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-danger-alt">
                        <img src="assets/img/icons/interface/icon-x.svg" alt="Included" className="m-2 icon icon-xs bg-danger" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-success-alt">
                        <img src="assets/img/icons/interface/icon-check.svg" alt="Included" className="m-2 icon icon-xs bg-success" data-inject-svg/>
                      </div>

                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">API Support</h6>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">

                      <div className="rounded-circle bg-danger-alt">
                        <img src="assets/img/icons/interface/icon-x.svg" alt="Included" className="m-2 icon icon-xs bg-danger" data-inject-svg/>
                      </div>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>3 Connections</span>

                    </div>
                    <div className="col-4 col-lg-3 d-flex justify-content-center py-3 py-md-4">
                      <span>Unlimited Connections</span>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="p-0">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mb-4 mb-md-0 text-center">
                <div className="px-xl-3">
                  <div>
                    <div className="d-inline-block mb-4 p-3 p-md-4 rounded-circle bg-primary-2-alt">
                      <img src="assets/img/icons/theme/navigation/route.svg" alt="Binoculars icon" className="icon bg-primary-2" data-inject-svg/>
                    </div>
                    <h5>Fully Responsive</h5>
                    <div>
                      Ned ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque Arya.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4 mb-md-0 text-center">
                <div className="px-xl-3">
                  <div>
                    <div className="d-inline-block mb-4 p-3 p-md-4 rounded-circle bg-primary-2-alt">
                      <img src="assets/img/icons/theme/layout/layout-left-panel-2.svg" alt="Layouts icon" className="icon bg-primary-2" data-inject-svg/>
                    </div>
                    <h5>Multiple Layouts</h5>
                    <div>
                      Non pulvinar neque laoreet suspendisse interdum Catelyn libero id. Olenna imp leo in vitae turpis massa. Sapien habitant Tyrion.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4 mb-md-0 text-center">
                <div className="px-xl-3">
                  <div>
                    <div className="d-inline-block mb-4 p-3 p-md-4 rounded-circle bg-primary-2-alt">
                      <img src="assets/img/icons/theme/shopping/box-2.svg" alt="Box icon" className="icon bg-primary-2" data-inject-svg/>
                    </div>
                    <h5>Modular Components</h5>
                    <div>
                      Khaleesi vedit sodales ut eu. Melisandre pellentesque elit eget Gendry est sociis natoque penatibus. Eunuch pulvinar sapien et Loras.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider divider-bottom"></div>
        </section>
        </div>  
    );
}