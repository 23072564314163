import React from 'react';
import Header from './Header';


export default function ContactPage() {
    return(
      <div>
        <div className="bg-primary-3 text-white p-0" data-overlay>
        <Header/>
      {/* <img src="assets/img/heros/hero-1.jpg" alt="Image" className="bg-image opacity-60"/> */}
      <section className="pb-0">
        <div className="container pb-5">
          <div className="row justify-content-center text-center">
            <div className="col-md-9 col-lg-8 col-xl-7">
              <h1 className="display-3">We’re here to help</h1>
              <p className="lead mb-0">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.</p>

            </div>
          </div>
        </div>
        <div className="divider divider-bottom"></div>
      </section>
    </div>
    <section className="pb-0">
      <div className="container">
        <div className="row section-title justify-content-center text-center">
          <div className="col-md-9 col-lg-8 col-xl-7">
            <h3 className="display-4">Contact Us</h3>
            <div className="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.</div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-9 col-xl-8">
            <form data-form-email novalidate action="https://mailform.mediumra.re/jumpstart/smtp.php">
              <div className="form-row">
                <div className="col-sm">
                  <div className="form-group">
                    <label for="contact-name">Your Name</label>
                    <input type="text" name="contact-name" className="form-control" id="contact-name" required/>
                    <div className="invalid-feedback">
                      Please type your name.
                    </div>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="form-group">
                    <label for="contact-email">Email Address</label>
                    <input type="email" name="contact-email" className="form-control" id="contact-email" placeholder="you@website.com" required/>
                    <div className="invalid-feedback">
                      Please provide your email address.
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm">
                  <div className="form-group">
                    <label for="contact-company">Company (optional)</label>
                    <input type="text" name="contact-company" className="form-control" id="contact-company"/>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="form-group">
                    <label for="contact-phone">Phone (optional)</label>
                    <input type="tel" name="contact-phone" className="form-control" id="contact-phone"/>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label for="contact-message">Message</label>
                <textarea id="contact-message" name="contact-message" rows="10" className="form-control" required></textarea>
                <div className="invalid-feedback">
                  Please tell us a little more.
                </div>
              </div>
              <div className="form-row">
                <div className="col-12">
                  <div data-recaptcha data-sitekey="6Lfmz4gUAAAAAEau9IKfGA7leOKYAiRhv-3WA3gq"></div>
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <div className="d-none alert alert-success" role="alert" data-success-message>
                    Thanks, a member of our team will be in touch shortly.
                  </div>
                  <div className="d-none alert alert-danger" role="alert" data-error-message>
                    Please fill all fields correctly.
                  </div>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary btn-loading" type="submit" data-loading-text="Sending">
                      {/* <!-- Icon for loading animation --> */}
                      {/* <svg className="icon bg-white" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Icon For Loading</title>
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g>
                            <polygon points="0 0 24 0 24 24 0 24" opacity="0"></polygon>
                          </g>
                          <path d="M12,4 L12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,10.9603196 17.7360885,9.96126435 17.2402578,9.07513926 L18.9856052,8.09853149 C19.6473536,9.28117708 20,10.6161442 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z"
                          fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "></path>
                        </g>
                      </svg> */}
                      <span>Send Message</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-auto" data-aos="fade-up">
            <div className="px-md-4 px-lg-5 mb-5 mb-md-0">
              <h5>Email us</h5>
              <a href="mailto:hello@geneus.org" className="lead">hello@geneus.org</a>
            </div>
          </div>
          <div className="col-md-auto" data-aos="fade-up" data-aos-delay="100">
            <div className="px-md-4 px-lg-5 mb-5 mb-md-0">
              <h5>Drop in</h5>
              <address>
                <p>Shop B3, Emmanuel Plaza, 150 Agbani Road</p>
                <p>Enugu</p>
                <p>Nigeria</p>

              </address>
              <a target="_blank"  rel="noopener noreferrer" href="https://www.google.com/maps/place/150+Agbani+Rd,+Achara,+Enugu/@6.4130947,7.4951965,3a,75y,301.06h,90t/data=!3m7!1e1!3m5!1sVa0DLHit9OuQSgxPc8L78Q!2e0!6s%2F%2Fgeo3.ggpht.com%2Fcbk%3Fpanoid%3DVa0DLHit9OuQSgxPc8L78Q%26output%3Dthumbnail%26cb_client%3Dsearch.gws-prod.gps%26thumb%3D2%26w%3D86%26h%3D86%26yaw%3D301.06183%26pitch%3D0%26thumbfov%3D100!7i13312!8i6656!4m5!3m4!1s0x1044a113b3782369:0xee17791ac299405b!8m2!3d6.4131844!4d7.4950592?hl=en">View Location</a>
            </div>
          </div>
          <div className="col-md-auto" data-aos="fade-up" data-aos-delay="200">
            <div className="px-md-4 px-lg-5 mb-5 mb-md-0">
              <h5>Give us a call</h5>
              <a href="Tel:+2349061371449" className="lead">+234 906 137 1449</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="divider divider-bottom bg-primary-3"></div>
    </div>
    );
}