import React from 'react';
import Header from './Header';
import Hero from './Hero';
import Testimonials from './Testimonials';
import Portfolio from './Portfolio';
import Team from './Team';
import Pricing from './Pricing';
import About from './About';
import Questions from './faq';


export default function Home() {
    return(
        <div>
            {/* <div className="loader">
                <div className="loading-animation"></div>
            </div> */}

            <Header/>
            <Hero/>
            <Testimonials/>  
            <Portfolio/>
            <Team/> 
            <Pricing/>
            <About/>
            <Questions/>               
        </div>
        
    );
}