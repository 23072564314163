import React from 'react';




export default function Testimonials() {
    return(
        
        <section className="bg-primary-3 text-white o-hidden pb-0" id="testimonials">
            <div className="container">
                <div className="row section-title justify-content-center text-center" >
                    <div className="col-md-9 col-lg-8 col-xl-7">
                        <h3 className="display-4">Customers are lovin’ Us</h3>
                        <div className="lead">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa.</div>
                    </div>
                </div>
            </div>
            <div className="highlight-selected" data-flickity='{ "imagesLoaded": true, "wrapAround":true, "pageDots":false, "adaptiveHeight":true, "autoPlay":3000 }'>
                <div className="carousel-cell col-xl-7 col-md-8">
                    <div className="row align-items-center justify-content-start justify-content-sm-center mx-3 mx-xl-4">
                        <div className="col-sm-auto mb-4 mb-sm-0">
                            <img className="img-fluid avatar avatar-xl" src="assets/img/avatars/male-4.jpg" alt="Harvey Derwent avatar"/>
                        </div>
                        <div className="col pl-lg-4">
                            <h4 className="h2">&ldquo;We are working at almost twice the capacity&rdquo;</h4>
                            <p>
                                We had all sorts of problems around motivation and productivity from our smallest scrums to our largest teams. Geneus helped us rise above all and conquer.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="carousel-cell col-xl-7 col-md-8">
                    <div className="row align-items-center justify-content-start justify-content-sm-center mx-3 mx-xl-4">
                        <div className="col-sm-auto mb-4 mb-sm-0">
                            <img className="img-fluid avatar avatar-xl" src="assets/img/avatars/male-1.jpg" alt="Harvey Derwent avatar"/>
                        </div>
                        <div className="col pl-lg-4">
                            <h4 className="h2">&ldquo;Geneus increases productivity.&rdquo;</h4>
                            <p>
                                We had all sorts of problems around motivation and productivity from our smallest scrums to our largest teams.
                                <mark data-aos="highlight-text" data-aos-delay="200">Geneus helped us rise above all and conquer.</mark>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="carousel-cell col-xl-7 col-md-8">
                    <div className="row align-items-center justify-content-start justify-content-sm-center mx-3 mx-xl-4">
                        <div className="col-sm-auto mb-4 mb-sm-0">
                            <img className="img-fluid avatar avatar-xl" src="assets/img/avatars/female-4.jpg" alt="Shelley McNabb avatar"/>
                        </div>
                        <div className="col pl-lg-4">
                            <h4 className="h2">&ldquo;Long build times are a thing of the past!&rdquo;</h4>
                            <p>
                                We all know the stigma around build times and the ever expanding arsenal of tooling in modern web apps. Fear not, Geneus does away with all of that.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="carousel-cell col-xl-7 col-md-8">
                    <div className="row align-items-center justify-content-start justify-content-sm-center mx-3 mx-xl-4">
                        <div className="col-sm-auto mb-4 mb-sm-0">
                            <img className="img-fluid avatar avatar-xl" src="assets/img/avatars/female-3.jpg" alt="Mary Goddard avatar"/>
                        </div>
                        <div className="col pl-lg-4">
                            <h4 className="h2">&ldquo;Top notch support on-call? Yes please.&rdquo;</h4>
                            <p>
                                Product support comes first when we are considering a new service. Do yourself a favour and choose Geneus, we have found the support to be second to none.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="carousel-cell col-xl-7 col-md-8">
                    <div className="row align-items-center justify-content-start justify-content-sm-center mx-3 mx-xl-4">
                        <div className="col-sm-auto mb-4 mb-sm-0">
                            <img className="img-fluid avatar avatar-xl" src="assets/img/avatars/male-2.jpg" alt="Bradley Singleton avatar"/>
                        </div>
                        <div className="col pl-lg-4">
                            <h4 className="h2">&ldquo;Geneus gives us total peace of mind.&rdquo;</h4>
                            <p>
                                Living up to their reputation for support and reliablity, Geneus has delivered once again on all fronts. Any bugs we find are dealt with promptly.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="position-absolute w-50 h-100 top right" data-jarallax-element="100 48">
                <div className="blob blob-2 w-100 h-100 top right bg-gradient opacity-50"></div>
            </div>
            <div className="divider divider-bottom bg-white"></div>
        </section>
        
    );
}